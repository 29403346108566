export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/install',
      name: 'install',
      component: () => import('@/views/Install'),
    },
    {
      path: '/',
      redirect: { name: 'Inicio' },
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      meta: { requiresAuth: true },
      children: [
        {
          path: 'mapa',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Mapa'),
        },
        {
          path: 'changepass',
          name: 'Cambiar Contraseña',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'checkagenda',
          name: 'Agenda',
          component: () => import('@/views/Agendas/AgendasCheck'),
        },
        {
          path: 'checkagenda/finalizar/:id',
          name: 'Finalizar agenda',
          component: () => import('@/views/Agendas/AgendasFinalizar'),
        },
        {
          path: 'agendagrid',
          name: 'grid',
          component: () => import('@/views/Agendas/FullCalendar'),
          meta: { is_referente: true },
        },
        {
          path: 'agendas',
          meta: { label: 'Agenda', is_referente: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista de Eventos',
              path: '',
              component: () => import('@/views/Agendas/AgendasTable'),
            },
            {
              path: 'create',
              name: 'Nueva agenda',
              component: () => import('@/views/Agendas/AgendasCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar agenda',
              component: () => import('@/views/Agendas/AgendasEdit'),
            },
            {
              path: 'show/:id',
              name: 'Visor Agenda',
              component: () => import('@/views/Agendas/AgendasEdit'),
            },
          ],
        },
        {
          path: 'usuarios',
          meta: { label: 'Usuarios', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Usuarios',
              path: '',
              component: () => import('@/views/Usuarios/UsuariosTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Usuario',
              component: () => import('@/views/Usuarios/UsuariosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: () => import('@/views/Usuarios/UsuariosEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Usuario',
              component: () => import('@/views/Usuarios/UsuariosClearPass'),
            },
          ],
        },
        {
          path: 'barrios',
          meta: { label: 'Barrios', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista de Barrios',
              path: '',
              component: () => import('@/views/Barrios/BarriosTable'),
            },
            {
              path: 'create',
              name: 'Nueva Zona',
              component: () => import('@/views/Barrios/BarriosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Zona',
              component: () => import('@/views/Barrios/BarriosEdit'),
            },
          ],
        },
        {
          path: 'candidatos',
          meta: { label: 'Candidatos', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista de Candidatos',
              path: '',
              component: () => import('@/views/Candidatos/CandidatosTable'),
            },
            {
              path: 'create',
              name: 'Nuevo candidato',
              component: () => import('@/views/Candidatos/CandidatosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar candidato',
              component: () => import('@/views/Candidatos/CandidatosEdit'),
            },
          ],
        },
        {
          path: 'referentes',
          meta: { label: 'Referentes', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista de Referentes',
              path: '',
              component: () => import('@/views/Referentes/ReferentesTable'),
            },
            {
              path: 'create',
              name: 'Alta de Referente',
              component: () => import('@/views/Referentes/ReferentesCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Referente',
              component: () => import('@/views/Referentes/ReferentesEdit'),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/PageNotFound'),
    },
  ],
}
