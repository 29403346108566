import Toast from 'vue-toastification'
import Vue from 'vue'
import 'vue-toastification/dist/index.css'

const options = {
  // transition: 'Vue-Toastification__fade',
  transition: 'Vue-Toastification__slideBlurred',
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false
    }
    // You can modify the toast if you want
    return toast
  },
}

Vue.use(Toast, options)
