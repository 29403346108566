<template>
  <div class="container">
    Hay una actualizacion disponible!
    <b-button pill size="sm" variant="info" @click.stop="updatePWA"
      ><i class="fas fa-sync"></i> Actualizar</b-button
    >
  </div>
</template>

<script>
export default {
  methods: {
    updatePWA() {
      this.$events.fire('evento-update-pwa')
    },
  },
}
</script>
