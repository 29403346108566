<template>
  <router-view />
</template>
<style lang="scss">
@import '~@/assets/scss/style';
</style>
<script>
import update from '@/mixins/update'
import Component from '@/components/toast/toastButtonUpdate'
export default {
  mixins: [update],
  mounted() {
    if (this.updateExists) {
      this.sendToastUpdate()
    }
  },
  methods: {
    sendToastUpdate() {
      this.$toast.info(Component, {
        position: 'bottom-right',
        timeout: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
  },
  events: {
    'evento-update-pwa'() {
      this.$toast.clear()
      this.refreshApp()
    },
  },
}
</script>
